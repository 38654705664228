@import '~app/mixins';

.editor {
  .header {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    padding-bottom: 12px;
    border-bottom: 2px solid #ccc;
    color: $color-grey;
  }

  .top {
    display: flex;
    margin-bottom: 10px;

    .subHeader {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 8px;
      color: $color-grey;
      border-bottom: 2px solid transparent;

      &.border {
        border-bottom-color: #ccc;
      }
    }

    p {
      color: #666;
      font-size: 15px;
      line-height: 22px;
    }

    .reprocess {
      color: #666;
      font-size: 13px;
      display: flex;
      margin-top: 12px;

      &.hidden {
        visibility: hidden;
      }

      div:last-child {
        margin-left: 10px;
      }
    }

    .left {
      width: 300px;
      margin-right: 40px;

      .items {
        margin-top: 25px;
        padding-top: 25px;
        border-top: 2px solid #ccc;

        .item {
          margin-bottom: 12px;
          color: $color-grey;
          font-size: 16px;
          cursor: pointer;
          font-weight: bold;

          &.current {
            @include primary-color();

            font-weight: bold;
          }

          &.unavailable {
            color: $color-grey-light;
            pointer-events: none;
          }

          &.disabled {
            pointer-events: none;
          }
        }
      }
    }

    .right {
      p {
        max-width: 630px;
        margin: 10px 0 5px;
      }

      .stepContainer {
        width: 630px;
        min-height: 535px;
        position: relative;
        overflow: hidden;
        background-color: $color-white;
        box-shadow: 5px 3px 44px rgba(0, 0, 0, 0.16);

        .steps {
          width: 300%;
          display: flex;
          position: absolute;
          transition: left 0.3s ease-out;

          .step {
            width: 100%;

            .inner {
              position: relative;

              /*
              .title {
                border-top: 1px solid #dadfe7;
                border-bottom: 1px solid #dadfe7;
                padding: 6px 0;
                font-weight: bold;
                margin-bottom: 15px;

                @include primary-color();
              }
              */

              .description {
                color: $color-grey;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 20px;
                white-space: pre-wrap;
                text-align: left;
              }

              .stepContent {
                text-align: center;

                .contactStep {
                  .rate {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;

                    > div {
                      padding: 10px 0 10px 15px;
                      text-align: left;

                      &:first-child {
                        width: 175px;
                        padding-left: 10px;
                      }

                      &:nth-child(2) {
                        width: 130px;
                      }

                      &:nth-child(3) {
                        width: 130px;
                      }

                      &:last-child {
                        width: 140px;
                      }
                    }

                    div label span {
                      font-weight: bold;
                      cursor: pointer;
                    }
                  }

                  img {
                    width: 100%;
                  }

                  .center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 35px;

                    .listAutomator {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin-top: 35px;
                      width: 450px;
                      position: relative;

                      .bar {
                        width: 360px;
                        background-color: #ccc;
                        height: 2px;
                        margin: 0 auto 15px;
                      }

                      button {
                        position: absolute;
                        right: 0;
                        bottom: 8px;
                      }
                    }
                  }
                }

                .paymentStep {
                  padding-right: 20px;
                }

                .confirmStep {
                  margin: 30px;
                  text-align: left;
                }

                .infoPrices {
                  margin: 15px 0;

                  .price {
                    font-size: 13px;
                    color: $color-grey-light;
                    font-style: italic;
                    margin: 5px;
                  }
                }

                .dnc {
                  margin-top: 40px;
                  border-top: 1px solid $color-grey-light;
                  padding-top: 20px;

                  .description {
                    text-align: left;
                  }
                }

                .info {
                  display: flex;
                  margin: 10px 0;

                  .label {
                    width: 175px;
                    text-align: right;
                    margin-right: 10px;
                    font-size: 14px;
                    color: $color-grey;
                  }

                  label {
                    cursor: pointer;

                    span {
                      padding-left: 4px;
                      padding-right: 15px;
                    }
                  }
                }

                .message {
                  color: #b00;
                  margin: 20px;
                }

                .smallTitle {
                  color: $color-grey-1;
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 18px;
                  text-decoration: underline;
                }

                .input {
                  display: block;
                  padding: 5px 8px;
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  box-shadow: inset 0 1px 0 0 #ececec;
                  font-size: 14px;
                  width: 100%;
                  color: $color-grey-dark;
                }

                .confirmation {
                  margin-top: 10px;

                  @include primary-color();
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    text-align: right;

    button {
      min-width: 90px;
      margin-left: 8px;
    }

    button:first-child {
      margin-right: 8px;
    }

    .border {
      margin: 30px auto 0;
      width: 80%;
      border-top: 2px solid #ccc;
    }
  }
}

.dncDisclaimer {
  .disclaimer {
    font-size: 13px;
    color: $color-grey;
    text-align: justify;
  }

  .buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 10px 5px;
    }
  }
}
